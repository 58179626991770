// src/components/AdminHeader.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { FaBars } from 'react-icons/fa';

const AdminHeader = ({ toggleSidebar, title }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/adminLogin'); // Redirect to login on logout
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <header className="w-full bg-gray-800 text-white py-4 px-6 flex justify-between items-center fixed top-0 left-0">
      {/* AppBar Button */}
      <button
        onClick={toggleSidebar}
        className="text-white text-2xl focus:outline-none hover:text-gray-400"
      >
        <FaBars />
      </button>

      {/* Title */}
      <h2 className="text-xl font-semibold ml-4"> {title}</h2>

      {/* Profile Avatar */}
      <div className="relative">
        <div
          className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span className="text-lg font-bold text-white">A</span>
        </div>
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-40 bg-white text-gray-800 rounded-lg shadow-lg">
            <ul>
              <li
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setIsDropdownOpen(false);
                  navigate('/adminDetails');
                }}
              >
                Profile
              </li>
              <li
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={handleLogout}
              >
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default AdminHeader;
