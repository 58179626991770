import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faXTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage("Please enter a valid email address.");
      clearMessageAfterTimeout();
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "subscriptions"), {
        email: email,
        timestamp: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
      setMessage("Subscription successful!");
      setEmail("");
    } catch (error) {
      console.error("Error adding document: ", error);
      setMessage("An error occurred. Please try again.");
    }
    clearMessageAfterTimeout();
  };

  const clearMessageAfterTimeout = () => {
    setTimeout(() => {
      setMessage("");
    }, 4000); // 4-second duration
  };

  return (
    <footer className="bg-gray-900 text-gray-200">
      <div className="container mx-auto px-8 py-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* About Us Section */}
        <div>
          <h3 className="font-bold text-lg mb-2">About Us</h3>
          <p className="text-sm text-start">
            Homchang is an Independent Media Agency founded in 2020 with aim of
            bringing wide range of issues to the forefront. Our aim to start a
            conversation and critical discourse among the readers. We aim to
            cover policy, politics, culture, economic, and current affairs with
            all the detailed research. Our editorial, opinions, and the
            contribution from Guest Writer seek to go beyond the news in putting
            voice and cover what's important.
          </p>
          <div className="flex justify-between mt-4">
            <a
              href="https://www.facebook.com/homchangnewspaper"
              className="text-gray-400 w-auto hover:text-white"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://x.com/homchangoffl"
              className="text-gray-400 w-auto hover:text-white"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a
              href="https://www.instagram.com/homchang_northeast/"
              className="text-gray-400 w-auto hover:text-white"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.youtube.com/@homchang_offl" 
              className="text-gray-400 w-auto hover:text-white"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>

        {/* Useful Links Section */}
        {/* <div>
          <h3 className="font-bold text-lg mb-4">Useful Links</h3>
          <ul className="space-y-2 text-sm">
            <li>
              <a href="/about" className="hover:text-white">
                Hati-Barsani
              </a>
            </li>
            <li>
              <a href="/news" className="hover:text-white">
                Archives
              </a>
            </li>
            <li>
              <a href="/partners" className="hover:text-white">
                Partners
              </a>
            </li>
            <li>
              <a href="https://youtu.be/e5ou8eqKElg?si=u14EehCazLdXVuLj" className="hover:text-white"  target="_blank" rel="noopener noreferrer">
                Only Raw
              </a>
            </li>
            <li>
              <a href="https://youtube.com/@vop_hgofficial?si=nK4fg9N9TP3oasG-" className="hover:text-white"  target="_blank" rel="noopener noreferrer">
                Vineet's On Point
              </a>
            </li>
            <li>
              <a href="/contacts" className="hover:text-white">
                Contacts
              </a>
            </li>
          </ul>
        </div> */}

        {/* Help Section */}
        <div className="text-center">
          <h3 className="font-bold text-lg mb-4">Help?</h3>
          <ul className="space-y-2 text-sm">
            <li>
              <a href="/faq" className="hover:text-white">
                FAQ
              </a>
            </li>
            <li>
              <a href="/terms" className="hover:text-white">
                policy
              </a>
            </li>
            <li>
              <a href="/reporting" className="hover:text-white">
                Advertisements 
              </a>
            </li>
            <li>
              <a href="/documentation" className="hover:text-white">
                Join Us
              </a>
            </li>
            <li>
              <a href="/privacy" className="hover:text-white">
                Privacy
              </a>
            </li>
          </ul>
        </div>

        {/* Subscription Section */}
        <div>
          <h3 className="font-bold text-lg mb-4">Subscribe</h3>
          <form onSubmit={handleSubmit} className="flex gap-2">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
              className="w-full px-4 py-2 rounded-l bg-gray-800 text-gray-300"
              required
            />
            <button
              type="submit"
              className="px-4 py-2 rounded-r text-white hover:bg-orange-700 get_button"
            >
              Subscribe
            </button>
          </form>
          {message && <p className="text-sm text-green-400 mt-2">{message}</p>}
        </div>
      </div>

      {/* Legal Section */}
      <div className="bg-gray-800 text-gray-400 text-center py-4">
        <p className="text-sm">&copy; 2025 Homchang. All Rights Reserved.</p>
        <p className="text-sm">
          Designed by{" "}
          <a
            href="https://cozy-shayak.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:underline"
          >
            Sahayak
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
