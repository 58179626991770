// src/components/Header.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import logo from "../assets/HG_logo.png";
const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <header className="text-white py-4 px-6 flex justify-between items-center">
      <div
        className="flex items-center hover:cursor-pointer sm:flex-col sm:items-start md:flex-row md:items-center"
        onClick={handleClick}
      >
        <LazyLoadImage
          src={logo}
          alt="Company Logo"
          effect="blur"
          className="h-8 sm:h-6 md:h-8 lg:h-10 xl:h-10 mr-2 sm:mr-0 md:mr-2"
        />
      </div>

      <div className="sm:hidden">
        <button
          onClick={toggleDrawer}
          className="text-white focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
      <nav className="hidden sm:flex space-x-6">
        <ul className="flex space-x-6">
          <li>
            <a href="/what-is-what" className="block hover:text-gray-200">
              What is what?
            </a>
          </li>
          <li>
            <a href="/" className="block hover:text-gray-200">
              Podcast
            </a>
          </li>
          <li>
            <a href="/guest-writers" className="block hover:text-gray-200">
              Guest Writers
            </a>
          </li>
          <li>
            <a href="/about-us" className="block hover:text-gray-200">
              Who are we?
            </a>
          </li>
          <li>
            <a href="/contact-us" className="block hover:text-gray-200">
              Connect
            </a>
          </li>
        </ul>
      </nav>
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleDrawer}
        ></div>
      )}
      <div
        className={`fixed top-0 right-0 h-full bg-black text-white w-64 z-50 transform ${
          isDrawerOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300`}
      >
        <div className="p-6 flex justify-between items-center">
          {/* <h2 className="text-xl font-bold">Navbar</h2> */}
          {/* NAVBAR COMPANY NAME */}
          <button
            onClick={toggleDrawer}
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <ul className="space-y-7 p-6">
          <li>
            <a href="/" className="block hover:text-gray-200">
              Home
            </a>
          </li>
          <li>
            <a href="/what-is-what" className="block hover:text-gray-200">
              What is what?
            </a>
          </li>
          <li>
            <a href="/" className="block hover:text-gray-200">
              Podcast
            </a>
          </li>
          <li>
            <a href="/guest-writers" className="block hover:text-gray-200">
              Guest Writers
            </a>
          </li>
          <li>
            <a href="/about-us" className="block hover:text-gray-200">
              Who are we?
            </a>
          </li>
          <li>
            <a href="/gallery" className="block hover:text-gray-200">
              Gallery
            </a>
          </li>
          <li>
            <a href="/contact-us" className="block hover:text-gray-200">
              Connect
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
