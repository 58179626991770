import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { db } from "../../firebaseConfig"; // Import Firestore instance
import { collection, addDoc } from "firebase/firestore";

function ContactUs() {
    const headline = "Contact Us";
    
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
    });
    const [status, setStatus] = useState("");

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Submitting...");
        try {
            // Add document to Firestore collection
            await addDoc(collection(db, "contacts"), formData);
            setStatus("Message sent successfully!");
            setFormData({ name: "", email: "", phoneNumber: "", message: "" });
        } catch (error) {
            console.error("Error submitting message: ", error);
            setStatus("Error submitting message. Please try again.");
        }
        clearMessageAfterTimeout(); 
    };

    const clearMessageAfterTimeout = () => {
      setTimeout(() => {
        setStatus('');
      }, 4000); // 4-second duration
    };

    return (
        <div>
            <Header headline={headline} />
            <div className="flex flex-wrap justify-evenly bg-gray-900 text-white p-8">
                {/* Form Section */}
                <div className="flex-1 min-w-[300px] px-4 mb-8">
                    <h1 className="text-2xl font-semibold mb-4">Contact our team</h1>
                    <p className="text-justify text-base mb-6">
                        We are always happy to hear from you about new ideas or
                        information that may clarify, expand on, or even contradict
                        our published work. We ask, however, that you refrain from
                        providing us with non-public information that you are required
                        to keep confidential. In addition, please refrain from sending
                        us any personal health, financial, and/or other identifying
                        information concerning any individual.
                    </p>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Full Name"
                            className="w-full px-4 py-2 border rounded-md text-gray-900"
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                            className="w-full px-4 py-2 border rounded-md text-gray-900"
                            required
                        />
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder="Phone number"
                            className="w-full px-4 py-2 border rounded-md text-gray-900"
                            required
                        />
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Leave us a message..."
                            className="w-full px-4 py-2 border rounded-md text-gray-900 h-24"
                            required
                        ></textarea>
                        <button
                            type="submit"
                            className="w-full bg-red-600 hover:bg-red-700 text-white py-2 rounded-md"
                        >
                            Send message
                        </button>
                    </form>
                    {status && <p className="mt-4 text-center">{status}</p>}
                </div>

                {/* Info Section */}
                <div className="flex-1 min-w-[300px] px-4">
                    <h2 className="text-xl font-semibold mb-4">Chat with us</h2>
                    <p className="mb-2">
                        <a
                            href="mailto:neiconnect@homchang.in"
                            className="text-blue-400 hover:underline"
                        >
                            Shoot us an email
                        </a>
                    </p>
                    <p className="mb-2">
                        <a
                            href="https://x.com/homchangoffl"
                            className="text-blue-400 hover:underline"
                        >
                            Message us on X
                        </a>
                    </p>
                    <p className="mb-2">
                        <a
                            href="https://www.instagram.com/homchang_northeast/"
                            className="text-blue-400 hover:underline"
                        >
                            Message us on Instagram
                        </a>
                    </p>
                    <p className="mb-8">
                        <a
                            href="https://www.facebook.com/homchangnewspaper"
                            className="text-blue-400 hover:underline"
                        >
                            Message us on Facebook
                        </a>
                    </p>

                    <h2 className="text-xl font-semibold mb-4">Call us</h2>
                    <p>Call our team Mon-Fri from 9am to 5pm.</p>
                    <p>
                        <a
                            href="tel:+919864891986"
                            className="text-blue-400 hover:underline"
                        >
                            +91-9864891986
                        </a>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;
