// src/components/Login.js
import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../firebaseConfig";
import Logo from "../../assets/HG_logo.png";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false); // Popup state
  const [resetEmail, setResetEmail] = useState(""); // Email for reset
  const [resetMessage, setResetMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError("Login successful!");
      setEmail("");
      setPassword("");
      clearMessageAfterTimeout(3000);
      navigate("/adminDashboard"); 
    } catch (err) {
      setError("Failed to log in. Please check your credentials.");
      setEmail("");
      setPassword("");
      clearMessageAfterTimeout(6000);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage("Password reset email sent!");
      setTimeout(() => {
        setForgotPasswordOpen(false);
        setResetMessage("");
        setResetEmail("");
      }, 2000);
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setResetMessage("No user found with this email address.");
      } else {
        setResetMessage("Error sending reset email. Please try again.");
      }
    }
  };

  const clearMessageAfterTimeout = (timing) => {
    setTimeout(() => {
      setError("");
    }, timing); // n-second duration
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-orange-500 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 border border-gray-300 p-8 rounded-lg bg-white shadow-md">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Company Logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome back
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please enter your details
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <button
                onClick={() => setForgotPasswordOpen(true)}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot password?
              </button>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign in
            </button>
          </div>
        </form>
        {error && <p className="text-red-500 text-sm">{error}</p>}

        {isForgotPasswordOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
              <h3 className="text-xl font-semibold mb-4">Reset Password</h3>
              <form onSubmit={handleForgotPassword}>
                <input
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                  required
                />
                <button
                  type="submit"
                  className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                >
                  Send Reset Email
                </button>
              </form>
              {resetMessage && (
                <p className="text-green-500 mt-3">{resetMessage}</p>
              )}
              <button
                onClick={() => setForgotPasswordOpen(false)}
                className="text-red-500 mt-3 block mx-auto"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
