import React, { useState, useEffect } from "react";

const ArticleCard = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Fetch articles from the backend
    fetch("https://homchang.in//articles_af.php")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Articles:", data); // Debugging fetched data
        // Ensure that data is an array before setting state
        if (Array.isArray(data)) {
          setArticles(data);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
      });
  }, []);

  if (articles.length === 0) {
    return (
      <div className="text-center mt-10 text-lg text-gray-600">
        No articles available.
      </div>
    );
  }

  return (
    <div className="max-w-7xl px-2 sm:px-4 lg:px-2">
      <section className="article-list">
        {articles.map((article, index) => (
          <a
            key={article.ArticleID}
            href={article.PageUrl || "#"}
            className={`block bg-white ${
              index !== articles.length - 1 ? "border-b-2 border-black" : ""
            } max-w-full text-left text-justify p-2 sm:p-4 lg:p-2 hover:shadow-lg transition-shadow duration-300 ease-in-out`}
          >
            <div className="article-details">
              {/* Headline */}
              <h2 className="text-xl sm:text-xl lg:text-2xl font-bold text-gray-800 line-clamp-2 lg:line-clamp-1">
                {article.Title || "Untitled"}
              </h2>

              {/* Description */}
              <p className="text-sm sm:text-base lg:text-lg lg:font-medium text-gray-600 mb-2 line-clamp-3 lg:line-clamp-2">
                {article.Content}
              </p>

              {/* Author and Date */}
              <p className="text-xs sm:text-sm lg:text-base text-gray-600 flex justify-between items-center italic">
                <span className="font-medium text-gray-500">
                  {article.AuthorName || "Unknown"}
                </span>
                <span className="text-gray-500">
                  {article.PublishedDate
                    ? new Date(article.PublishedDate).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )
                    : "No date available"}
                </span>
              </p>
            </div>
          </a>
        ))}
      </section>
    </div>
  );
};

export default ArticleCard;
