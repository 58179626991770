// src/components/HeroBar.js
import React from "react";
import { useNavigate } from "react-router-dom";

import "./style.css";
const HeroBar = ({ headline, date, navigateTo}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
  if (navigateTo) {
    if (navigateTo.startsWith("http://") || navigateTo.startsWith("https://")) {
      // Open external link in a new tab
      window.open(navigateTo, "_blank", "noopener,noreferrer");
    } else {
      // Navigate to internal route
      navigate(navigateTo);
    }
  }
};

  return (
    <section className="text-white py-10 px-6 sm:px-6 lg:px-12 text-start">
  <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">
    {headline}
  </h2>
  <p className="text-sm sm:text-base lg:text-lg mb-6">
    {date}
  </p>

  {navigateTo && (
    <button
      className="text-white px-4 py-2 rounded-full bg-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out get_button"
      onClick={handleButtonClick}
    >
      Get Registered  
    </button>
  )}
</section>

  );
};

export default HeroBar;
