import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import imageUrl1 from "../../assets/img_a-01.jpg";
import imageUrl from "../../assets/img_a-1.jpg";
import imageUrl2 from "../../assets/img_a-2.jpg";
import imageUrl3 from "../../assets/img_a-3.jpg";
import imageUrl4 from "../../assets/img_a-4.jpg";

const Article = () => {
  return (
    <>
      <Header />

      <div className="main-body">
        <div className="larger-section">
          <div className="flex flex-col items-center justify-center text-justify p-2 lg:p-6 overflow-hidden">
            {/* Image */}
            {/* <span className="font-medium text-gray-500">
                Editorial
              </span> */}

              <p className="text-sm text-gray-600 flex justify-between items-center w-full">
              <span className="font-medium text-gray-500">Rudi Debbarma</span>
              <span className="font-medium text-gray-500">
                Editorial
              </span>
              <span className="font-medium text-gray-500">12.01.2025</span>
            </p>

            {/* Content */}
            {/* Headline */}
            <h1 className="text-xl font-bold text-gray-800 mb-4">
              Is Tripura's New Logo Actually About It's People and Ethos?
            </h1>
            <LazyLoadImage
              src={imageUrl1}
              alt="News"
              className="h-44 lg:h-64 object-contain mb-4"
            />
{/* <p className="text-sm text-gray-600 flex justify-between items-center w-full">
              <span className="font-medium text-gray-500">Rudi Debbarma</span>
              <span className="font-medium text-gray-500">12.01.2025</span>
            </p> */}
            {/* Details */}
            <p className="text-gray-600 text-base mb-4 text-left">
              Tripura gets a unique state official logo, rebranding the common
              Ashoka emblem, which makes it the 6th state in North-East to adopt
              a unique logo. But has it actually failed to serve the primary
              purpose of having a unique state emblem?
            </p>
            <p className="text-gray-600 text-lg mb-4">
              To put things into perspective, take Meghalaya's new state logo
              for comparison. The Meghalaya's new unique emblem is the best
              example of a logo that is able to do the most complete and fullest
              representation of one's state.
            </p>
            <LazyLoadImage
              src={imageUrl}
              alt="News"
              className="h-44 lg:h-64 object-fill mb-4"
            />

            <p className="text-gray-600 text-lg mb-4">
              Meghalaya logo consists of three mountain peaks, symbolising the
              state's geography- Khasi, Garo and Jaintia Hills. It also features
              three monoliths signifying the 3 tribes of the state- Khasi, Garo
              and Jaintia. In addition to that, there is a cloud that denotes
              the state name- "Abode of clouds".
            </p>
            <p className="text-gray-600 text-lg mb-4">
              Not only that. At the bottom there is a Wangala festival drum,
              that is a traditional drum of the state. There is also a Rigitok
              and Paila beaded necklace in an inverted semi circle shape, which
              is a traditional necklace of Meghalaya.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              But in the contrast, if we take a look at the Tripura's new
              official logo, it appears to lack to integrate elements that
              embodies the state's unique culture and identity. The logo is a
              Ashoka emblem against the backdrop of Tripura map in white, which
              inturn is set against the backdrop of a circle shape in saffron
              colour with a green outline.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              The CM wrote in social media platform that "This emblem will be a
              proud representation of Tripura's history, culture and people" but
              is yet to provide a thorough explanation of the meanings each
              elements in the logo represents.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              The colour scheme- Safron, White and Green could be symbolic of
              Indian flag but if we notice carefully, that is not the case. The
              colours don't follow the correct order in the Indian flag.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              The green colour appears at the topmost as outine and its area is
              significantly less compared to safron and white. The big prominent
              area of safron could suggest something apparent to many, but may
              not be so much appreciative to the Indigenous populace.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              It's not just Meghalaya but every other North-East state which
              have adopted an unique state's logo, attempted to make an
              appropriate representation of their state culture, tradition and
              people through it. There is nothing arbitrary or generic in the
              logo, but every single element has a philosophy or meaning
              associated with it.
            </p>

            <LazyLoadImage
              src={imageUrl2}
              alt="News"
              className="h-44 lg:h-64 object-fill mb-4"
            />

            <p className="text-gray-600 text-lg mb-4">
              Arunachal Pradesh is another state with an unique logo that is
              worth appreciating because of how just with a simple design, it is
              able to effectively represent the unique things of their state.
              The logo consist of two mountain peaks, a rising sun and two
              hornbills.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              The sun stands for the state's name " Land of dawn lit mountains",
              the two mountain peak depicts Komdi and Daphabum peaks which is
              the first place which receives sunlight in India. The hornbill
              represents the state's animal.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              The Manipur's state logo is also not generic but very symbolic to
              their state. The logo is a mythical figure called "Kanglasha",
              which is a half-lion and half-dragon. A very significant figure in
              Meitei's mythology and religion.
            </p>
            <LazyLoadImage
              src={imageUrl3}
              alt="News"
              className="h-44 lg:h-64 object-fill mb-4"
            />

            <p className="text-gray-600 text-lg mb-4">
              Even Sikkim still uses coat of arms of Sikkim Kingdom and the
              House of Namgyal as their official state logo. This evokes the
              thought that the idea of having Tripura Kingdom's coat of arms as
              State official logo also could have been an option.
            </p>
            <LazyLoadImage
              src={imageUrl4}
              alt="News"
              className="h-44 lg:h-64 object-fill mb-4"
            />
            <p className="text-gray-600 text-lg mb-4">
              There are many things which are very symbolic and distinctive to
              Tripuri people and Tripura. Bamboo is a very significant material,
              used by Tripuri Indigenous people in almost every areas of life.
              It also could have been the primary symbol in the logo.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              Tripura traditional houses are build of bamboos called
              "Gairing-Nok". It also hold a sacred place in Tipra original
              religion also. Bamboo is the most indispensable material. Wathwi
              bamboo is used to build Wathob, to represent deiteis of original
              Tipra religion. It is required in rituals and other religious
              activities.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              Therefore, bamboo could have been the most fitting alternative to
              embody Tripura distinctive identity through a emblem. Other than
              that bamboo is used to make utensils, fencing, furnitures etc.
              Tripura's aboriginal people are also major consumer of bamboo
              shoots.
            </p>
            <p className="text-gray-600 text-lg mb-4">
              The new emblem of Tripura may have missed an opportunity to convey
              a more complete and resonant story of Tripura's unique heritage by
              not incorporating more thoughtful symbols like bamboo.
            </p>
          </div>
        </div>

        <div className="smaller-section">{/* Empty section */}</div>
      </div>

      <Footer />
    </>
  );
};

export default Article;
