// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Firebase auth instance

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = auth.currentUser !== null;

  return isAuthenticated ? children : <Navigate to="/adminLogin" />;
};

export default ProtectedRoute;
