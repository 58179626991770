import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const AboutUs = () => {
    const headline = "About Us";

  return (
    <>
    <Header headline={headline} />
    <div className="flex justify-center items-center lg:py-5 bg-white">
      <div className="max-w-4xl bg-gray-300 p-10 lg:shadow-lg rounded-none lg:rounded-lg">
        <h1 className="text-3xl font-semibold text-gray-900 mb-6 text-center">About Our Company</h1>
        <p className="text-lg text-gray-700 mb-4 leading-relaxed text-justify">
          Homchang was founded on 19th January, 2021 as an Independent Media agency based in Tripura.
          Its primary motive was to educate and raise awareness among young students about politics, social,
          economic, policies, and cultural affairs of Northeast India.
        </p>
        <p className="text-lg text-gray-700 mb-4 leading-relaxed text-justify">
          Over time, the media agency also began writing editorials criticizing and advocating Government,
          Non-Governmental organizations, and civil societies.
        </p>
        <p className="text-lg text-gray-700 mb-4 leading-relaxed text-justify">
          Here, we try to incorporate the experiences and knowledge of the old and wise with young and fresh ideas.
          Homchang looks ahead to inform and empower its readers with not only new but also critical outlooks and perspectives.
        </p>
      </div>
    </div>

    <Footer />
    </>
  );
};

export default AboutUs;
