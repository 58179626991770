import React, { useState, useEffect } from "react";
import AdminSidebar from "./SideBar";
import AdminHeader from "./Header";

function NewArticle() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [authors, setAuthors] = useState([]);
  const [articleForm, setArticleForm] = useState({
    title: "",
    content: "",
    authorId: "",
    pageUrl: "",
    date: "",
  });
  const [editingArticleId, setEditingArticleId] = useState(null);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setIsSidebarOpen(true);

  useEffect(() => {
    // Fetch authors for the dropdown
    fetch("https://homchang.in//authors.php")
      .then((response) => response.json())
      .then((data) => setAuthors(Array.isArray(data) ? data : []))
      .catch((error) => console.error("Error fetching authors:", error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    fetch("https://homchang.in//articles_af.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(articleForm),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Parse JSON if response is valid
      })
      .then((data) => {
        if (data.success) {
          alert("Article added successfully!");
          resetForm();
        } else {
          alert(data.error || "Error adding article");
        }
      })
      .catch((error) => {
        console.error("Error adding article:", error);
        alert("An error occurred. Please try again later.");
      });
  };
  

  const resetForm = () => {
    setEditingArticleId(null);
    setArticleForm({ title: "", content: "", authorId: "", pageUrl: "", date: "" });
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />

      <div
        className={`flex-1 transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : "ml-0"
        }`}
      >
        <AdminHeader toggleSidebar={toggleSidebar} title="NEWS ARTICLE FORM" />
        <div className="pt-20 px-8">
          <h2 className="text-3xl font-semibold mb-6 text-blue-600">
            {editingArticleId ? "Update Article Card" : "Add New Article Card"}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Form Fields */}
            <div>
              <label className="block text-gray-700 font-medium mb-2">Headline</label>
              <input
                type="text"
                value={articleForm.title}
                onChange={(e) => setArticleForm({ ...articleForm, title: e.target.value })}
                placeholder="Enter Title"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Content</label>
              <textarea
                value={articleForm.content}
                onChange={(e) => setArticleForm({ ...articleForm, content: e.target.value })}
                placeholder="Enter Content"
                className="w-full p-2 border border-gray-300 rounded resize-none"
                rows="4"
              />
            </div>
            <div className="flex gap-4">
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Author</label>
                <select
                  value={articleForm.authorId}
                  onChange={(e) => setArticleForm({ ...articleForm, authorId: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">Select Author</option>
                  {authors.map((author) => (
                    <option key={author.AuthorID} value={author.AuthorID}>
                      {author.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Published Date</label>
                <input
                  type="date"
                  value={articleForm.date}
                  onChange={(e) => setArticleForm({ ...articleForm, date: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Page URL</label>
              <input
                type="text"
                value={articleForm.pageUrl}
                onChange={(e) => setArticleForm({ ...articleForm, pageUrl: e.target.value })}
                placeholder="Enter Page URL"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="flex items-center gap-4">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                {editingArticleId ? "Update Article" : "Add Article"}
              </button>
              {editingArticleId && (
                <button
                  type="button"
                  onClick={resetForm}
                  className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewArticle;
