// src/components/AdminSidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp} from 'react-icons/fa';

const AdminSidebar = ({ isOpen, closeSidebar }) => {
  const [isArticlesOpen, setIsArticlesOpen] = useState(false);

  const toggleArticlesDropdown = () => {
    setIsArticlesOpen((prev) => !prev);
  };

  return (
    <div
      className={`bg-gray-800 text-white h-full fixed top-0 ${
        isOpen ? 'left-0' : '-left-64'
      } w-64 transition-all duration-300 z-50`}
    >
      <div className="p-6">
        <h1 className="text-2xl text-orange-600 font-bold border-b border-orange-600 mb-4">Homchang</h1>
        <ul className="space-y-4 border-t border-orange-600">
          <li>
            <Link
              to="/adminDashboard"
              className="hover:bg-gray-700 p-2 block rounded"
              onClick={closeSidebar}
            >
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/adminDetails"
              className="hover:bg-gray-700 p-2 block rounded"
              onClick={closeSidebar}
            >
              Personal Details
            </Link>
          </li>
          <li>
            <Link
              to="/headlineHistory"
              className="hover:bg-gray-700 p-2 block rounded"
              onClick={closeSidebar}
            >
              Headlines
            </Link>
          </li>
          <li>
            {/* Dropdown for Articles */}
            <button
              className="hover:bg-gray-700 p-2 block rounded w-full text-left flex justify-between items-center"
              onClick={toggleArticlesDropdown}
            >
              Articles
              <span>{isArticlesOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </button>
            {isArticlesOpen && (
              <ul className="ml-4 space-y-2 mt-2">
                <li>
                  <Link
                    to="/cardHistory"
                    className="hover:bg-gray-700 p-2 block rounded"
                    onClick={closeSidebar}
                  >
                    Article History
                  </Link>
                </li>
                <li>
                  <Link
                    to="/newArticle"
                    className="hover:bg-gray-700 p-2 block rounded"
                    onClick={closeSidebar}
                  >
                    Create Article Card
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link
              to="/createArticle"
              className="hover:bg-gray-700 p-2 block rounded"
              onClick={closeSidebar}
            >
              Create Article
            </Link>
          </li>
          <li>
                  <Link
                    to="/newCampaign"
                    className="hover:bg-gray-700 p-2 block rounded"
                    onClick={closeSidebar}
                  >
                    Campaign Data
                  </Link>
                </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebar;
