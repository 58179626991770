import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAVp4XAjR4IcY4tGhgNduSxIs_EfTJ9vdo",
    authDomain: "news-website-fa1a5.firebaseapp.com",
    projectId: "news-website-fa1a5",
    storageBucket: "news-website-fa1a5.firebasestorage.app",
    messagingSenderId: "96290876036",
    appId: "1:96290876036:web:2c9f173a953d78bc29f0d1",
    measurementId: "G-TDQWPBNWV5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Authentication
const auth = getAuth(app);

// Function to monitor auth state
const monitorAuthState = (setAuthState) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthState(user); // Set user state
      } else {
        setAuthState(null); // Clear user state
      }
    });
  };

// Initialize RealtimeDB
const rdb = getDatabase();

// INitializa storage
const storage = getStorage(app);


export { app, db, auth, monitorAuthState, rdb, storage };
