// src/components/Gallery.js
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

// import img1 from '../../assets/HG_logo.png';
// import img2 from '../../assets/header_bg.jpg';
// import img3 from '../../assets/img1.jpg';
// import img4 from '../../assets/img2.jpg';

const Gallery = () => {
    const headline = "Gallery";

  // const images = [
  //   { id: 1, src: img1, alt: 'Image 1' },
  //   { id: 2, src: img2, alt: 'Image 2' },
  //   { id: 3, src: img3, alt: 'Image 3' },
  //   { id: 4, src: img4, alt: 'Image 4' },
  //   { id: 5, src: '/path-to-image5.jpg', alt: 'Image 5' },
  //   { id: 6, src: '/path-to-image6.jpg', alt: 'Image 6' },
  //   { id: 7, src: '/path-to-image7.jpg', alt: 'Image 7' },
  //   { id: 8, src: '/path-to-image8.jpg', alt: 'Image 8' },
  // ];

  return (
    <>
    <Header headline={headline} />
{/* <h2 className="text-3xl font-bold text-center mb-8">Gallery</h2> */}
    {/* <div className="bg-gray-100 min-h-screen pt-10">
      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-6 pb-10">
        {images.map((image) => (
          <div
            key={image.id}
            className="rounded overflow-hidden shadow-lg bg-white hover:scale-105 transition-transform duration-300"
          >
            <LazyLoadImage
              src={image.src}
              alt={image.alt}
              effect="blur"
              className="w-full h-64 object-fill"
            />
          </div>
        ))}
      </div> 
    </div> */}

<div className="flex items-center justify-center m-5">
      <div className="relative w-full max-w-5xl sm:p-8 lg:p-12 text-center bg-white rounded-lg shadow-2xl transform hover:scale-105 transition-transform duration-300">
        {/* Decorative Border */}
        <div className="absolute inset-0 -z-10 rounded-lg border-8 border-transparent animate-pulse bg-gradient-to-r from-red-500 via-orange-500 to-black"></div>

        {/* Main Content */}
        <h1 className="text-4xl sm:text-6xl lg:text-8xl font-extrabold text-white bg-clip-text bg-gradient-to-r from-red-500 via-orange-500 to-black drop-shadow-md">
          UNDER DEVELOPMENT
        </h1>
        <p className="mt-4 sm:mt-6 text-lg sm:text-xl lg:text-2xl font-medium text-white">
        Thank you for visiting. The site will be operational soon.
        </p>
      </div>
    </div>

<Footer />

    </>
  );
};

export default Gallery;
