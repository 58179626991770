import React, { useEffect, useRef } from "react";

import Header from '../../components/NavBar';

const WhatIsWhat = () => {
  const sectionsRef = useRef([]);

  const sections = [
    {
      title: "WHAT is WHAT?",
      content:
        "At Homchang, we work on various projects, from documentation and archives to opinion columns, and editorials. Most of these projects are an appreciation or a criticism of different bodies responsible for the growth and development of NORTHEAST INDIA. This is why it becomes vital for our readers to know the difference between the type of work we present.",
      bg: "bg-gray-100 text-black",
    },
    {
      title: "EDITORIAL",
      content:
        "Homchang editorial discusses any recent news, development, or a collection of some events with research backing the facts. Editorials in Homchang focus on policies, stances, initiatives and schemes by pressure groups, government bodies, and political parties (regional and national which are active in Northeast India).",
      bg: "bg-black text-white",
    },
    {
      title: "ARCHIVE",
      content:
        "States like Tripura in Northeast India have very little documentation of the important happenings in the pre-social media era. Events like the Twipra Students’ Federations (TSF) Rigna-Risa movement, the Kokborok Language Movement, and many more were not documented properly. Homchang’s archive project seeks to resolve this. A collection of all the important happenings along with its timeline, visuals, and links to recorded evidence from other sources are some of the data this project will contain.",
      bg: "bg-gray-100 text-black",
    },
    {
      title: "FEATURE",
      content:
        "At ‘feature’, the correspondents of Homchang talk to a certain personality, individual and organization whose work or endeavour has been inspirational and “your time to read it” worthy. These articles delve into not struggles and personal details of the personality or organization but into the individual’s insight on how someone else can achieve the same.",
      bg: "bg-black text-white",
    },
    {
      title: "OP-ED",
      content:
        "Both Advocacy and Criticism are important for any institution or community to grow. Homchang’s Op-Ed is an attempt to become a hammer and a stair when necessary. This segment provides varying opinions from contributors. The content of this Op-Ed is aimed at empowering voices and celebrating strong voices whether in the interest or of differences.",
      bg: "bg-gray-100 text-black",
    },
    {
      title: "GUEST COLUMN",
      content:
        "Homchang’s guest column is a special project kept to bridge the gap between experts and our readers. These experts are educators, scholars and professionals from different fields and walks of life. This column allows them to give insight into their take on the recent happenings in their field of study or profession. Guest Column is an effort to bring an inclusive expert and experience driven ideas expressed in writings.",
      bg: "bg-black text-white",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = sectionsRef.current;
      const windowHeight = window.innerHeight;

      let closestSection = null;
      let closestDistance = Number.MAX_VALUE;

      sections.forEach((section) => {
        const { top } = section.getBoundingClientRect();
        const distance = Math.abs(top);

        if (distance < closestDistance && top < windowHeight) {
          closestDistance = distance;
          closestSection = section;
        }
      });

      if (closestSection) {
        closestSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    let timeout = null;
    const onScroll = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(handleScroll, 100); // Throttle scrolling
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="scroll-smooth">
      <div className="fixed top-0 left-0 right-0 z-10 bg-black bg-opacity-20">
      <Header />
      </div>
      {/* First Section: Always Visible */}
      <section
        className={`scroll-section h-screen flex flex-col justify-center px-8 md:px-16 lg:px-32 xl:px-48 text-start relative ${sections[0].bg}`}
      >
        <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold text-amber-400 mb-6">
          {sections[0].title}
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl max-w-6xl leading-relaxed">
          {sections[0].content}
        </p>
      </section>

      {/* Remaining Sections: Scrollable */}
      {sections.slice(1).map((section, index) => (
        <section
          key={index + 1}
          ref={(el) => (sectionsRef.current[index] = el)}
          className={`scroll-section h-screen flex flex-col justify-center px-8 md:px-16 lg:px-32 xl:px-48 text-start relative ${section.bg}`}
        >
          <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold text-amber-400 mb-6">
            {section.title}
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl max-w-6xl leading-relaxed">
            {section.content}
          </p>
        </section>
      ))}
    </div>
  );
};

export default WhatIsWhat;
