import React, { useState, useEffect } from "react";
import AdminSidebar from "./SideBar";
import AdminHeader from "./Header";
import { FaLongArrowAltRight } from 'react-icons/fa';


const CampaignData = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(true);
  };

  const handleSendMessage = (row) => {
    // Handle the action to send a message for the specific row
    alert(`Message sent to campaign: ${row["Name"]}`);
  };

  useEffect(() => {
    // Fetch data from the backend
    fetch("https://homchang.in//campaignData.php")
      .then((response) => response.json())
      .then((responseData) => {
        console.log("Fetched Data:", responseData);
        if (responseData.columns && responseData.data) {
          // Add the Action column to the columns array
          setColumns([...responseData.columns, "Action"]);
          setData(responseData.data);
        } else {
          console.error("Invalid data format:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="flex h-full bg-gray-100">
      {/* Sidebar */}
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />

      {/* Main content */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? "ml-64" : "ml-0"}`}>
        {/* Header */}
        <AdminHeader toggleSidebar={toggleSidebar} title="Campaign Data" />

        {/* Dashboard Content */}
        <div className="pt-20 px-6">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-500">
                {columns.map((column, index) => (
                  <th key={index} className="border border-gray-300 px-4 py-2 text-left">
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} className="bg-gray-100">
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className="border border-gray-300 px-4 py-2">
                      {column === "Action" ? (
                        <button
                          onClick={() => handleSendMessage(row)}
                          className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                           <FaLongArrowAltRight />
                        </button>
                      ) : (
                        row[column] || "-"
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CampaignData;
