import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaUserTie,
  FaPhoneAlt,
  FaEnvelope,
  FaFlag,
  FaFlagCheckered,
} from "react-icons/fa";
import "./style.css";

import Header from '../../components/NavBar';

const CampaignForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    country: "",
    state: "",
    customState: "",
  });

  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name: formData.name,
      contact: formData.contact,
      email: formData.email,
      country: formData.country,
      state: formData.state === "Other" ? formData.customState : formData.state,
      customState: formData.customState,
    };

    try {
      const response = await fetch("https://homchang.in//submit_form.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(payload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.message === "Form submitted successfully!") {
        setShowPopup(true); // Show popup on successful submission
        setFormData({
          name: "",
          contact: "",
          email: "",
          country: "",
          state: "",
          customState: "",
        });
      } else {
        alert("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred: " + error.message);
    }
  };

  const closePopup = () => {
    setShowPopup(false); // Close the popup
    navigate("/");
  };

  return (
   <div className="min-h-screen bg-animated">
   <Header />
    <div className=" flex justify-center items-center text-white p-6">
      <form
        className="border bg-white bg-opacity-20 shadow-md rounded px-8 pt-6 pb-8 w-full max-w-lg"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-amber-400">
          Campaign Registration
        </h2>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2 flex items-center">
            <FaUserTie className="mr-2" /> Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-amber-400 hover:border-amber-400 transition-colors duration-200"
            // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-amber-400"
            // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2 flex items-center">
            <FaPhoneAlt className="mr-2" /> Contact Number
          </label>
          <input
            type="tel"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-amber-400"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2 flex items-center">
            <FaEnvelope className="mr-2" />
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-amber-400"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2 flex items-center">
            <FaFlag className="mr-2" />
            Country
          </label>
          <select
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-amber-400"
            required
          >
            <option value="">Select Country</option>
            <option value="India">India</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {formData.country === "India" && (
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 flex items-center">
              <FaFlagCheckered className="mr-2" />
              State
            </label>
            <select
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-amber-400"
              required
            >
              <option value="">Select State</option>
              {indianStates.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
        )}

        {formData.country === "Other" && (
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Custom State</label>
            <input
              type="text"
              name="customState"
              placeholder="Enter State"
              value={formData.customState}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:ring-amber-400"
            />
          </div>
        )}

        <button
          type="submit"
          className="bg-amber-400 hover:bg-amber-500 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:scale-105 ripple"
        >
          Submit
        </button>
      </form>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white text-black p-6 rounded shadow-md max-w-sm text-center">
            <h2 className="text-lg font-bold mb-4">Thank You!</h2>
            <p className="mb-4 font-semibold">You are now a part of Homchang</p>
            <p className="mb-4">
              Our first 20 educharts will be given out for free. A correspondent
              will connect with you soon for distribution.
            </p>
            <button
              onClick={closePopup}
              className="bg-amber-400 hover:bg-amber-500 text-black font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
   </div>
  );
};

export default CampaignForm;
