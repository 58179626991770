import React, { useRef, useEffect } from "react";
import Thomas from '../../assets/Thomas Malsom.png';
import Pankaj from '../../assets/Pankaj Debbarma.png';

import Header from '../../components/NavBar';

const GuestWriters = () => {
  const writersRef = useRef([]);

  const writers = [
    {
      name: "Thomas Malsom",
      bio: "He is a Guest Writer associated with Homchang. Currently a Ph.D. scholar at the Department of Sociology, North-Eastern Hill University, Shillong, Meghalaya. His research interests include sociology of development, tribal/ indigenous studies, and social policy. His research works have been published in the journals “Economic and Political Weekly (India)” and “Intersectionalities: A Global Journal of Social Work Analysis, Research, Polity and Practice(Canada)”. He is also co-author with Walter Fernandes of the book entitled “Ethnicity and Land Conflicts: Development-Induced Displacement in Tripura 1947-2010”.  (mcthomashlutie@gmail.com, +929862492971)",
      image: Thomas,
      socialLinks: {
        twitter: "https://twitter.com/johndoe",
        linkedin: "https://linkedin.com/in/johndoe",
        facebook: "https://facebook.com/johndoe",
      },
      bg: "bg-gray-100 text-black",
      imageLeft: false,
    },
    {
      name: "Pankaj Debbarma",
      bio: "He is a Guest Writer with Homchang. He writes about Tripura contemporary politics, language movement, and current affairs. Also, he writes with keen observations about Tipra experience, capturing the essence of people, places, and everything that connects them. As a prolific columnist, his columns also appear on several dailies of Tripura. Earlier, he had also established Yakhrai, a digital sanctuary to bridge the gap between scholars and the public. Presently, he is an assistant professor of Geography teaching at the Maharaja Bir Bikram College, Aguli (Agartala).",
      image: Pankaj,
      socialLinks: {
        twitter: "https://twitter.com/janesmith",
        linkedin: "https://linkedin.com/in/janesmith",
        facebook: "https://facebook.com/janesmith",
      },
      bg: "bg-black text-white",
      imageLeft: true,
    },
    {
      name: "Lincoln Murasingh",
      bio: "He is a Guest Writer with Homchang. He is a poet, activist, and columnist writing on the development of Kokborok Language and Tiprasa culture. Currently, he is a working professional in the State Bank of India (SBI).",
      image: "https://via.placeholder.com/150",
      socialLinks: {
        twitter: "https://twitter.com/emilybrown",
        linkedin: "https://linkedin.com/in/emilybrown",
        facebook: "https://facebook.com/emilybrown",
      },
      bg: "bg-gray-100 text-black",
      imageLeft: false,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = writersRef.current;
      const windowHeight = window.innerHeight;

      let closestSection = null;
      let closestDistance = Number.MAX_VALUE;

      sections.forEach((section) => {
        const { top } = section.getBoundingClientRect();
        const distance = Math.abs(top);

        if (distance < closestDistance && top < windowHeight) {
          closestDistance = distance;
          closestSection = section;
        }
      });

      if (closestSection) {
        closestSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    let timeout = null;
    const onScroll = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(handleScroll, 100); // Throttle scrolling
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="scroll-smooth">
      <div className="fixed top-0 left-0 right-0 z-10 bg-black bg-opacity-20">
      <Header />
      </div>

      {writers.map((writer, index) => (
        <section
          key={index}
          ref={(el) => (writersRef.current[index] = el)}
          className={`scroll-section h-screen py-8 flex flex-col justify-center items-center px-8 md:px-16 lg:px-32 xl:px-48 text-center relative ${writer.bg}`}
        >
          <div
            className={`flex flex-col md:flex-row ${writer.imageLeft ? "md:flex-row-reverse" : "md:flex-row"} items-center justify-center space-y-8 md:space-x-8 md:space-y-0`}
          >
            <img
              src={writer.image}
              alt={writer.name}
              className="rounded w-60 h-60 md:w-80 md:h-80 mb-6"
            />
            <div className="text-center md:text-left">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-amber-400 mb-4">
                {writer.name}
              </h1>
              <p className="text-base md:text-lg lg:text-xl max-w-4xl leading-relaxed mb-6 text-start">
                {writer.bio}
              </p>
              <div className="flex justify-center md:justify-start space-x-4">
                {writer.socialLinks.twitter && (
                  <a
                    href={writer.socialLinks.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-amber-400 hover:text-amber-300 text-2xl"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}
                {writer.socialLinks.linkedin && (
                  <a
                    href={writer.socialLinks.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-amber-400 hover:text-amber-300 text-2xl"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                )}
                {writer.socialLinks.facebook && (
                  <a
                    href={writer.socialLinks.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-amber-400 hover:text-amber-300 text-2xl"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default GuestWriters;
