import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaTrash, FaEdit } from "react-icons/fa";
import AdminSidebar from "./SideBar";
import AdminHeader from "./Header";

const CreateArticle = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });
  const [articleData, setArticleData] = useState({
    title: "",
    content: "",
    publishedDate: "",
    authorID: "",
    categoryID: "",
  });
  const [editingArticleID, setEditingArticleID] = useState(null);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setIsSidebarOpen(true);

  const fetchData = async (type, setData) => {
    try {
      const response = await fetch(`https://homchang.in//get_data.php?type=${type}`);
      const result = await response.json();
      if (result.success) {
        setData(result.data);
      } else {
        console.error(`Error fetching ${type}:`, result.message);
      }
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    }
  };

  useEffect(() => {
    fetchData("authors", setAuthors);
    fetchData("categories", setCategories);
    fetchData("articles", setArticles);
  }, []);

  const generateSlug = (title) =>
    title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-|-$/g, "");

  const handleArticleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: "", type: "" });

    const slug = generateSlug(articleData.title);
    const dataToSubmit = { ...articleData, slug };

    const endpoint = editingArticleID
    ? "https://https://homchang.in//update_article.php"
    : "https://homchang.in//add_article.php";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...dataToSubmit,
          ArticleID: editingArticleID,
        }),
      });
    
      const result = await response.json();
      setIsLoading(false);
    
      if (result.success) {
        // Success handling
        setMessage({
          text: editingArticleID
            ? "Article updated successfully!"
            : "Article added successfully!",
          type: "success",
        });
        setEditingArticleID(null);
        setArticleData({
          title: "",
          content: "",
          publishedDate: "",
          authorID: "",
          categoryID: "",
        });
        fetchData("articles", setArticles);
      } else {
        console.error("Server Error:", result.message); // Log server error
        setMessage({ text: result.message, type: "error" });
      }
    } catch (error) {
      console.error("Fetch Error:", error); // Log fetch error
      setIsLoading(false);
      setMessage({ text: "An error occurred while submitting the article.", type: "error" });
    }
  };

  const handleEditArticle = (article) => {
    setEditingArticleID(article.ArticleID);
    setArticleData({
      title: article.Title,
      content: article.Content,
      publishedDate: article.PublishedDate,
      authorID: article.AuthorID,
      categoryID: article.CategoryID,
    });
  };
  const handleCancelEdit = () => {
    setEditingArticleID(null);
    setArticleData({ title: "", content: "", authorId: "", date: "", categoryID: "" });
  };

  const handleDeleteArticle = async (articleID) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        const response = await fetch(`https://homchang.in//delete_article.php?id=${articleID}`, {
          method: "DELETE",
        });

        const result = await response.json();
        if (result.success) {
          alert("Article deleted successfully!");
          fetchData("articles", setArticles);
        } else {
          alert("Failed to delete article: " + result.message);
        }
      } catch (error) {
        console.error("Error deleting article:", error);
      }
    }
  };

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link"],
    ],
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <div
        className={`flex-1 transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : "ml-0"
        }`}
      >
        <AdminHeader toggleSidebar={toggleSidebar} title="Create Article" />
        <div className="pt-20 px-8">
          <h2 className="text-3xl font-semibold mb-6 text-blue-600">
            {editingArticleID ? "Edit Article" : "Add New Article"}
          </h2>

          {message.text && (
            <div
              className={`p-4 rounded-md mb-6 ${
                message.type === "success"
                  ? "bg-green-100 text-green-700"
                  : message.type === "info"
                  ? "bg-blue-100 text-blue-700"
                  : "bg-red-100 text-red-700"
              }`}
            >
              {message.text}
            </div>
          )}

          <form onSubmit={handleArticleSubmit} className="space-y-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">Title</label>
              <input
                type="text"
                placeholder="Enter article title"
                value={articleData.title}
                onChange={(e) => setArticleData({ ...articleData, title: e.target.value })}
                required
                className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-200 focus:outline-none"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Content</label>
              <ReactQuill
                value={articleData.content}
                onChange={(content) => setArticleData({ ...articleData, content })}
                modules={quillModules}
                placeholder="Write your article content here..."
              />
            </div>
            <div className="flex flex-wrap gap-6">
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Published Date</label>
                <input
                  type="date"
                  value={articleData.publishedDate}
                  onChange={(e) =>
                    setArticleData({ ...articleData, publishedDate: e.target.value })
                  }
                  required
                  className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-200 focus:outline-none"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Author</label>
                <select
                  value={articleData.authorID}
                  onChange={(e) => setArticleData({ ...articleData, authorID: e.target.value })}
                  required
                  className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-200 focus:outline-none"
                >
                  <option value="">Select an author</option>
                  {authors.map((author) => (
                    <option key={author.AuthorID} value={author.AuthorID}>
                      {author.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Category</label>
                <select
                  value={articleData.categoryID}
                  onChange={(e) => setArticleData({ ...articleData, categoryID: e.target.value })}
                  required
                  className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-200 focus:outline-none"
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.CategoryID} value={category.CategoryID}>
                      {category.CategoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-3 rounded-lg text-white ${
                isLoading
                  ? "bg-blue-400 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700"
              }`}
            >
              {isLoading
                ? editingArticleID
                  ? "Updating..."
                  : "Submitting..."
                : editingArticleID
                ? "Update Article"
                : "Add Article"}
            </button>
            {editingArticleID && (
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              )}
          </form>

          <h3 className="text-2xl font-semibold mt-12 mb-4 text-gray-800">Existing Articles</h3>
          <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200 text-gray-600 text-sm uppercase">
              <tr>
                <th className="p-4 text-left">ID</th>
                <th className="p-4 text-left">Title</th>
                <th className="p-4 text-left">Page URL</th>
                <th className="p-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article) => (
                <tr key={article.ArticleID} className="border-t">
                  <td className="p-4">{article.ArticleID}</td>
                  <td className="p-4">{article.Title}</td>
                  <td className="p-4">
                    <a
                      href={`/articles/${article.Slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      /articles/{article.Slug}
                    </a>
                  </td>
                  <td className="p-4 flex space-x-2">
                    <button
                      onClick={() => handleEditArticle(article)}
                      className="text-blue-500 hover:underline"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteArticle(article.ArticleID)}
                      className="text-red-500 hover:underline"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CreateArticle;
