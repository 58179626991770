// src/pages/AdminDashboard.js
import React, { useState } from 'react';
import AdminSidebar from './SideBar';
import AdminHeader from './Header';

const AdminDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // State for managing form inputs
  const [authorData, setAuthorData] = useState({ name: '', email: '', contactNumber: '', authorType: '' });
  const [categoryData, setCategoryData] = useState({ categoryName: '' });
  const [staffData, setStaffData] = useState({ userName: '', email: '', password: '', dateOfJoining: '', role: '', contactNumber: '' });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(true);
  };

  // Handlers for form submissions
  const handleAuthorSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://homchang.in//add_data.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        action: 'addAuthor',
        name: authorData.name,
        email: authorData.email,
        contactNumber: authorData.contactNumber,
        authorType: authorData.authorType,
      }),
    });
  
    const result = await response.json();
    if (result.success) {
      alert(result.message);
      setAuthorData({ name: '', email: '', contactNumber: '', authorType: '' });
    } else {
      alert(result.message);
    }
  };
  
  

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://homchang.in//add_data.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        action: 'addCategory',
        categoryName: categoryData.categoryName,
      }),
    });
  
    const result = await response.json();
    if (result.success) {
      alert(result.message);
      setCategoryData({ categoryName: '' });
    } else {
      alert(result.message);
    }
  };
  
  const handleStaffSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://homchang.in//add_data.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        action: 'addStaff',
        userName: staffData.userName,
        email: staffData.email,
        password: staffData.password,
        dateOfJoining: staffData.dateOfJoining,
        role: staffData.role,
        contactNumber: staffData.contactNumber,
      }),
    });
  
    const result = await response.json();
    if (result.success) {
      alert(result.message);
      setStaffData({ userName: '', email: '', password: '', dateOfJoining: '', role: '', contactNumber: '' });
    } else {
      alert(result.message);
    }
  };
  
  

  return (
    <div className="flex h-full bg-gray-100">
      {/* Sidebar */}
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />

      {/* Main content */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        {/* Header */}
        <AdminHeader toggleSidebar={toggleSidebar} title="DASHBOARD" />

        {/* Dashboard Content */}
        <div className="pt-20 px-6">
          <h1 className="text-2xl font-bold mb-4 text-gray-800">Welcome, Admin!</h1>
          {/* <p className="text-gray-600 mb-8">This is your admin dashboard. Use the links on the left to navigate.</p> */}

          {/* Author Form */}
          <div className="bg-white p-6 border-b-2 border-black">
            <h2 className="text-2xl font-semibold mb-4 text-blue-500">Add Author</h2>
            <form onSubmit={handleAuthorSubmit} className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <input 
                  type="text" 
                  placeholder="Name" 
                  value={authorData.name} 
                  onChange={(e) => setAuthorData({ ...authorData, name: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input 
                  type="email" 
                  placeholder="Email" 
                  value={authorData.email} 
                  onChange={(e) => setAuthorData({ ...authorData, email: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input 
                  type="text" 
                  placeholder="Contact Number" 
                  value={authorData.contactNumber} 
                  onChange={(e) => setAuthorData({ ...authorData, contactNumber: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input 
                  type="text" 
                  placeholder="Author Type" 
                  value={authorData.authorType} 
                  onChange={(e) => setAuthorData({ ...authorData, authorType: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
              </div>
              <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">Add Author</button>
            </form>
          </div>

          {/* Category Form */}
          <div className="bg-white p-6 border-b-2 border-black">
            <h2 className="text-2xl font-semibold mb-4 text-blue-500">Add Category</h2>
            <form onSubmit={handleCategorySubmit} className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <input 
                  type="text" 
                  placeholder="Category Name" 
                  value={categoryData.categoryName} 
                  onChange={(e) => setCategoryData({ ...categoryData, categoryName: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
              </div>
              <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">Add Category</button>
            </form>
          </div>

          {/* Staff Form */}
          <div className="bg-white p-6">
            <h2 className="text-2xl font-semibold mb-4 text-blue-500">Add Staff</h2>
            <form onSubmit={handleStaffSubmit} className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <input 
                  type="text" 
                  placeholder="Username" 
                  value={staffData.userName} 
                  onChange={(e) => setStaffData({ ...staffData, userName: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input 
                  type="email" 
                  placeholder="Email" 
                  value={staffData.email} 
                  onChange={(e) => setStaffData({ ...staffData, email: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input 
                  type="password" 
                  placeholder="Password" 
                  value={staffData.password} 
                  onChange={(e) => setStaffData({ ...staffData, password: e.target.value })} 
                  required 
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input
                  type="date"
                  placeholder="Date of Joining"
                  value={staffData.dateOfJoining}
                  onChange={(e) => setStaffData({ ...staffData, dateOfJoining: e.target.value })}
                  required
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input
                  type="text"
                  placeholder="Role"
                  value={staffData.role}
                  onChange={(e) => setStaffData({ ...staffData, role: e.target.value })}
                  required
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
                <input
                  type="text"
                  placeholder="Contact Number"
                  value={staffData.contactNumber}
                  onChange={(e) => setStaffData({ ...staffData, contactNumber: e.target.value })}
                  required
                  className="border border-gray-300 rounded-lg p-3 focus:outline-blue-500"
                />
              </div>
              <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">Add Staff</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
