import React, { useEffect, useState } from 'react';
import { FaTrash, FaEdit, FaSave, FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import AdminSidebar from './SideBar';
import AdminHeader from './Header';

const HeadlineHistory = () => {
  const [headlines, setHeadlines] = useState([]);
  const [newHeadline, setNewHeadline] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const [newDate, setNewDate] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [editingHeadlineId, setEditingHeadlineId] = useState(null);
  const [updatedHeadline, setUpdatedHeadline] = useState('');
  const [updatedUrl, setUpdatedUrl] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchHeadlines = () => {
    fetch('https://homchang.in//headlines.php')
      .then((response) => response.json())
      .then((data) => setHeadlines(data))
      .catch((error) => console.error('Error fetching headlines:', error));
  };

  useEffect(() => {
    fetchHeadlines();
  }, []);

  const handleAddHeadline = (e) => {
    e.preventDefault();
    if (newHeadline.trim() === '' || newUrl.trim() === '' || newDate.trim() === '') {
      alert('Headline, URL, and Date cannot be empty.');
      return;
    }

    fetch('https://homchang.in//headlines.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title: newHeadline, url: newUrl, timestamp: newDate }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert('Headline added successfully!');
          setNewHeadline('');
          setNewUrl('');
          setNewDate('');
          fetchHeadlines();
        } else {
          alert(data.error || 'Error adding headline');
        }
      })
      .catch((error) => console.error('Error adding headline:', error));
  };

  const handleEdit = (id, title, url, timestamp) => {
    setEditingHeadlineId(id);
    setUpdatedHeadline(title);
    setUpdatedUrl(url);
    setUpdatedDate(timestamp);
  };

  const handleSave = (id) => {
    if (updatedHeadline.trim() === '' || updatedUrl.trim() === '' || updatedDate.trim() === '') {
      alert('Headline, URL, and Date cannot be empty.');
      return;
    }

    fetch('https://homchang.in//headlines.php', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, title: updatedHeadline, url: updatedUrl, timestamp: updatedDate }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert('Headline updated successfully!');
          setEditingHeadlineId(null);
          setUpdatedHeadline('');
          setUpdatedUrl('');
          setUpdatedDate('');
          fetchHeadlines();
        } else {
          alert(data.error || 'Error updating headline');
        }
      })
      .catch((error) => console.error('Error updating headline:', error));
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this headline?');
    if (confirmDelete) {
      fetch('https://homchang.in//headlines.php', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            fetchHeadlines();
          } else {
            alert(data.error || 'Error deleting headline');
          }
        })
        .catch((error) => console.error('Error deleting headline:', error));
    }
  };

  const handleCancel = () => {
    setEditingHeadlineId(null);
    setUpdatedHeadline('');
    setUpdatedUrl('');
    setUpdatedDate('');
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const filteredHeadlines = headlines.filter((headline) =>
    headline.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredHeadlines.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentHeadlines = filteredHeadlines.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div>
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={toggleSidebar} />
      <div className="ml-0 sm:ml-64">
        <AdminHeader toggleSidebar={toggleSidebar} title="NEWS HEADLINES" />
        <div className="pt-20 px-6">
          <div className="mb-6">
            <h1 className="text-3xl font-bold mb-4">Add New Headline</h1>
            <form onSubmit={handleAddHeadline} className="space-y-4">
              <input
                type="text"
                value={newHeadline}
                onChange={(e) => setNewHeadline(e.target.value)}
                placeholder="Enter headline"
                className="w-full p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                value={newUrl}
                onChange={(e) => setNewUrl(e.target.value)}
                placeholder="Enter URL"
                className="w-full p-2 border border-gray-300 rounded"
              />
              <input
                type="date"
                value={newDate}
                onChange={(e) => setNewDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                Add Headline
              </button>
            </form>
          </div>

          <div>
            <h1 className="text-3xl font-bold mb-6">Headline History</h1>
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search headlines..."
                className="w-1/2 p-2 border border-gray-300 rounded"
              />
              <select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="p-2 border border-gray-300 rounded"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>

            {currentHeadlines.length > 0 ? (
              <div>
                <table className="table-auto w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-gray-300 px-4 py-2">S.No</th>
                      <th className="border border-gray-300 px-4 py-2">Headline</th>
                      <th className="border border-gray-300 px-4 py-2">URL</th>
                      <th className="border border-gray-300 px-4 py-2">Date</th>
                      <th className="border border-gray-300 px-4 py-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentHeadlines.map((headline, index) => (
                      <tr key={headline.id}>
                        <td className="border border-gray-300 px-4 py-2">{startIndex + index + 1}</td>
                        <td className="border border-gray-300 px-4 py-2">
                          {editingHeadlineId === headline.id ? (
                            <input
                              type="text"
                              value={updatedHeadline}
                              onChange={(e) => setUpdatedHeadline(e.target.value)}
                              className="w-full p-2 border border-gray-300 rounded"
                            />
                          ) : (
                            headline.title
                          )}
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-blue-400 hover:text-blue-600 hover:underline">
                          {editingHeadlineId === headline.id ? (
                            <input
                              type="text"
                              value={updatedUrl}
                              onChange={(e) => setUpdatedUrl(e.target.value)}
                              className="w-full p-2 border border-gray-300 rounded"
                            />
                          ) : (
                            <a href={headline.url} target="_blank" rel="noopener noreferrer">
                              {headline.url}
                            </a>
                          )}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {editingHeadlineId === headline.id ? (
                            <input
                              type="date"
                              value={updatedDate}
                              onChange={(e) => setUpdatedDate(e.target.value)}
                              className="w-full p-2 border border-gray-300 rounded"
                            />
                          ) : (
                            new Date(headline.timestamp).toLocaleDateString('en-US')
                          )}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {editingHeadlineId === headline.id ? (
                            <>
                              <button onClick={() => handleSave(headline.id)} className="text-green-500 mx-2">
                                <FaSave />
                              </button>
                              <button onClick={handleCancel} className="text-red-500 mx-2">
                                <FaTimes />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() =>
                                  handleEdit(headline.id, headline.title, headline.url, headline.timestamp)
                                }
                                className="text-blue-500 mx-2"
                              >
                                <FaEdit />
                              </button>
                              <button onClick={() => handleDelete(headline.id)} className="text-red-500 mx-2">
                                <FaTrash />
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 mx-2 rounded ${
                      currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-700'
                    }`}
                  >
                    <FaChevronLeft />
                  </button>
                  <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 mx-2 rounded ${
                      currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-700'
                    }`}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              </div>
            ) : (
              <p>No headlines found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadlineHistory;
