import React from "react";
import NavBar from "./NavBar";
import HeroBar from "./HeroBar";

function Header({ headline, date, navigateTo }) {
  return (
    <div className="main_header">
      <NavBar />
      <HeroBar headline={headline} date={date} navigateTo={navigateTo}/>
    </div>
  );
}

export default Header;
