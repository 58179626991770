import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';

import Home from "./pages/clientSide/Home";
import ContactUs from "./pages/clientSide/ContactUs";
import AboutUs from './pages/clientSide/AboutUs';
import Gallery from "./pages/clientSide/Gallery";
import WhatIsWhat from "./pages/clientSide/WhatIsWhat";
import GuestWriters from './pages/clientSide/GuestWriters';

import Article from "./pages/clientSide/article";

import ArticlePage from './pages/clientSide/ArticlePage'; //dynamic report page

// import loader from './assets/HG post design_20250110_225259_0001.gif';

import ProtectedRoute from './pages/ProtectedRoute';

import AdminLogin from './pages/adminSide/AdminLogin';
import AdminDeshboard from './pages/adminSide/Deshboard';
import AdminDetails from './pages/adminSide/AdminDetails';
import HeadlineHistory from './pages/adminSide/HeadlineHistory';
import CreateArticle from './pages/adminSide/CreateArticle';

// import EditArticle from './pages/adminSide/EditArticle';

import CardHistory from './pages/adminSide/CardHistory';
import NewArticle from "./pages/adminSide/NewArticle";
import NewCampaign from './pages/adminSide/CampaignData';

import CampaignForm from "./pages/clientSide/CampaignForm";   //Temporary page


function App() {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
  }, []);

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen bg-white">
    <div className="flex flex-col items-center">
      {/* Spinner */}
      <div className="w-16 h-16 border-4 border-red-500 border-t-orange-500 border-b-black border-l-transparent rounded-full animate-spin"></div>

      {/* Loading Text */}
      {/* <p className="mt-4 text-2xl font-semibold text-white animate-pulse">
        <img src={loader} alt="loading..." className="w-32 h-32 rounded-full bg-gradient-to-br from-black via-gray-800 to-red-700 bg-opacity-70
"/>
      </p> */}
    </div>
  </div>
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Home Route */}
          <Route exact path="/" element={<Home />} />

          {/* Other Defined Routes */}
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/about-us" element={<AboutUs/>} />
          <Route exact path="/what-is-what" element={<WhatIsWhat/>} />
          <Route exact path="/guest-writers" element={<GuestWriters/>} />

          <Route exact path="/news/report1" element={<Article/>} />

          <Route exact path="/Kokborok-educhart-registration" element={<CampaignForm/>} />

          <Route path="/articles/:slug" element={<ArticlePage />} />


          {/* Catch-all Route for Undefined Paths */}
          <Route path="*" element={<Navigate to="/" />} />
    
          {/* AdminSide routes  */}
          <Route path="/adminLogin" element={<AdminLogin />} />

          
          {/* Admin Routes (Protected) */}

          {/* Redirect to dashboard if user is already logged in */}
        <Route
          path="/adminLogin"
          element={user ? <Navigate to="/adminDashboard" /> : <AdminLogin />}
        />
        <Route
          path="/adminDashboard"
          element={user ? <AdminDeshboard /> : <Navigate to="/adminLogin" />}
        />

          {/* <Route
          path="/adminDashboard"
          element={
            <ProtectedRoute>
              <AdminDeshboard />
            </ProtectedRoute>
          }
        /> */}

        <Route
          path="/adminDetails"
          element={
            <ProtectedRoute>
              <AdminDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/headlineHistory"
          element={
            <ProtectedRoute>
              <HeadlineHistory />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/cardHistory"
          element={
            <ProtectedRoute>
              <CardHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newArticle"
          element={
            <ProtectedRoute>
              <NewArticle />
            </ProtectedRoute>
          }

          />
          <Route
          path="/createArticle"
          element={
            <ProtectedRoute>
              <CreateArticle />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newCampaign"
          element={
            <ProtectedRoute>
              <NewCampaign />
            </ProtectedRoute>
          }
        />

          {/* <Route path="/edit-article/:slug" element={<EditArticle />} /> */}
        {/* <Route
          path="/edit-article/:slug"
          element={
            <ProtectedRoute>
              <EditArticle />
            </ProtectedRoute>
          }
        /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
