import React, { useState, useEffect } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";

import AdminSidebar from "./SideBar";
import AdminHeader from "./Header";

const CardHistory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [articles, setArticles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [authors, setAuthors] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(true);
  };

  const openModal = (article) => {
    setSelectedArticle(article);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedArticle(null);
  };

  // Fetch authors for the dropdown
  useEffect(() => {
    fetch("https://homchang.in//authors.php")
      .then((response) => response.json())
      .then((data) => setAuthors(Array.isArray(data) ? data : []))
      .catch((error) => console.error("Error fetching authors:", error));
  }, []);

  // Fetch articles from the backend
  useEffect(() => {
    fetch("https://homchang.in//get_article_card.php")
      .then((response) => response.json())
      .then((data) => setArticles(Array.isArray(data) ? data : []))
      .catch((error) => console.error("Error fetching articles:", error));
  }, []);

  // Function to update an article
  const updateArticle = async (article) => {
    try {
      const response = await fetch(
        "https://homchang.in//get_article_card.php",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(article),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Article updated successfully:", result.message);

        // Refresh the articles after update
        fetch("https://homchang.in//get_article_card.php")
          .then((response) => response.json())
          .then((data) => setArticles(Array.isArray(data) ? data : []))
          .catch((error) => console.error("Error fetching articles:", error));
      } else {
        console.error("Error updating article:", result.error);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  const deleteArticle = async (articleID) => {
    try {
      const response = await fetch(
        "https://homchang.in//get_article_card.php",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ArticleID: articleID }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Article deleted successfully:", result.message);

        // Refresh the articles after deletion
        fetch("https://homchang.in//get_article_card.php")
          .then((response) => response.json())
          .then((data) => setArticles(Array.isArray(data) ? data : []))
          .catch((error) => console.error("Error fetching articles:", error));
      } else {
        console.error("Error deleting article:", result.error);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  return (
    <div className="flex h-full bg-gray-100">
      {/* Sidebar */}
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />

      {/* Main content */}
      <div
        className={`flex-1 transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : "ml-0"
        }`}
      >
        {/* Header */}
        <AdminHeader toggleSidebar={toggleSidebar} title="Card History" />

        {/* Dashboard Content */}
        <div className="pt-20 px-6">
          <table className="table-auto border-collapse border border-gray-200 w-full">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Article ID</th>
                <th className="border border-gray-300 px-4 py-2">Title</th>
                <th className="border border-gray-300 px-4 py-2">Page URL</th>
                <th className="border border-gray-300 px-4 py-2">
                  Published Date
                </th>
                <th className="border border-gray-300 px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article) => (
                <tr key={article.ArticleID}>
                  <td className="border border-gray-300 px-4 py-2 truncate max-w-xs">
                    {article.ArticleID}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 truncate max-w-xs">
                    {article.Title}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 truncate max-w-xs">
                    <a
                      href={article.PageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      {article.PageUrl}
                    </a>
                  </td>
                  <td className="border border-gray-300 px-4 py-2 truncate max-w-xs">
                    {article.PublishedDate}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 truncate max-w-xs">
                    <button
                      onClick={() => openModal(article)}
                      className="text-blue-500 mx-2"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => deleteArticle(article.ArticleID)}
                      className="text-red-500 mx-2"
                    >
                      <FaTimes />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Edit Article</h2>
            {selectedArticle && (
              <div>
                <div className="mb-4">
                  <label className="block text-gray-700">Title</label>
                  <textarea
                    type="text"
                    rows={2}
                    className="border border-gray-300 p-2 w-full rounded resize-none"
                    value={selectedArticle.Title}
                    onChange={(e) =>
                      setSelectedArticle({
                        ...selectedArticle,
                        Title: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Content</label>
                  <textarea
                    type="text"
                    rows={5}
                    className="border border-gray-300 p-2 w-full rounded resize-none"
                    value={selectedArticle.Content}
                    onChange={(e) =>
                      setSelectedArticle({
                        ...selectedArticle,
                        Content: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Page URL</label>
                  <input
                    type="text"
                    className="border border-gray-300 p-2 w-full rounded"
                    value={selectedArticle.PageUrl}
                    onChange={(e) =>
                      setSelectedArticle({
                        ...selectedArticle,
                        PageUrl: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Published Date</label>
                  <input
                    type="date"
                    className="border border-gray-300 p-2 w-full rounded"
                    value={selectedArticle.PublishedDate}
                    onChange={(e) =>
                      setSelectedArticle({
                        ...selectedArticle,
                        PublishedDate: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Author</label>
                  <select
                    value={selectedArticle.AuthorID}
                    onChange={(e) =>
                      setSelectedArticle({
                        ...selectedArticle,
                        AuthorID: e.target.value,
                      })
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value=""> </option>
                    {authors.map((author) => (
                      <option key={author.AuthorID} value={author.AuthorID}>
                        {author.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end">
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                    onClick={() => {
                      updateArticle(selectedArticle);
                      closeModal();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardHistory;
